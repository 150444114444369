import { template as template_bd684693517543edb36e7c10b52ca22a } from "@ember/template-compiler";
const FKControlMenuContainer = template_bd684693517543edb36e7c10b52ca22a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
