import { template as template_13aff0e6630c42459e6722ad47811de1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_13aff0e6630c42459e6722ad47811de1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
