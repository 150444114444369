import { template as template_cbf5aa24f0fd4fe2991f0d83c8447a6e } from "@ember/template-compiler";
const FKText = template_cbf5aa24f0fd4fe2991f0d83c8447a6e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
