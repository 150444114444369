import { template as template_79014d9a75b94e5f9db3aefd52bac7a8 } from "@ember/template-compiler";
const WelcomeHeader = template_79014d9a75b94e5f9db3aefd52bac7a8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
